<template>
  <div class="pages">
    <h1>Editar dados para contato</h1>
    <p v-if="loading">Carregando dados...</p>
    <div class="wrapper" v-if="!loading">
      <div>
        <label for="email">Email</label>
        <input type="email" id="email" v-model="item.email" placeholder="Email para contato">
      </div>
      <div>
        <label for="emailForms">Email para receber os formulários de contato</label>
        <input type="email" id="emailForms" v-model="item.emailForms" placeholder="Email para receber formulários do site">
      </div>
      <div>
        <label for="phone">Telefone</label>
        <input type="text" id="phone" v-model="item.phone" placeholder="Telefone">
      </div>
      <div>
        <label for="address">Endereço</label>
        <input type="text" id="address" v-model="item.address" placeholder="Endereço">
      </div>
      <div>
        <label for="webmail">Link para acessar webmail</label>
        <input type="text" id="webmail" v-model="item.webmailLink" placeholder="Link webmail">
      </div>
      <br>
      <button class="btn save-btn" v-on:click="saveItem" :disabled="saving">{{ saving ? 'Salvando...' : 'Salvar' }}</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios').default;

export default {
  name: 'edititem',
  data() {
    return {
      loading: true,
      saving: false,
      savingImg: false,
      item: {
        email: '',
        emailForms: '',
        address: '',
        phone: '',
        webmailLink: ''
      }
    }
  },
  mounted() {
    let self = this;
    axios.get(this.$apiUrl + 'contact/')
            .then(response => {
              self.item = response.data.item;
              self.loading = false;
            })
            .catch(error => {
              this.$alert('', 'Erro', 'error');
              console.log(error.response.data.error);
              self.loading = false;
            });
  },
  methods: {
    saveItem() {
      let error = false;

      if (!error) {
        this.saving = true;
        let self = this;

        axios.put(this.$apiUrl + 'contact/', self.item)
                .then(response => {
                  self.saving = false;
                  this.$alert(response.data.message, 'Sucesso', 'success').then(() => {});
                })
                .catch(error => {
                  this.$alert(error.response.data.message, 'Erro', 'error');
                  console.log(error.response.data.error);
                  self.saving = false;
                });
      }
    }
  },
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/global";
  @import "../../assets/scss/buttons";

  .pages {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    position: relative;

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 10px;
    }

    .wrapper {
      padding: 50px 200px;
      @media screen and (max-width: 800px) {
        padding: 50px;
      }
    }

    .editor-wrapper {
      text-align: left;
      background-color: #fff;
      border: 1px solid $primary;
      border-radius: 10px;
    }

    label {
      display: block;
      text-align: left;

      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }

    input, select, textarea {
      padding: 10px;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 10px;
      font-size: 1rem;
    }

    select {
      -webkit-appearance: none;
    }

    .save-btn {
      border-radius: 10px;
    }

    img {
      max-width: 50%;
      height: auto;
    }
  }

  .delete-btn {
    background-color: #b13336;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 10px;

    &:hover {
      background-color: darken(#b13336, 20%);
    }
  }
</style>
